import React from "react"

import * as style from "./project_grid.module.sass"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const ProjectGrid = ({ source }) => {
  return (
    <div className={style.grid}>
      {console.log(source)}
      {source.map((props, i) => (
        <figure
          key={i}
          style={{
            gridColumn: "span " + props.spanColumns,
            aspectRatio: props.aspectRatio,
            margin: "0",
          }}
        >
          {props.image.file.contentType === "image/jpeg" ||
          props.image.file.contentType === "image/png" ? (
            <GatsbyImage
              className={style.img}
              image={props.image.gatsbyImageData}
              alt=""
            />
          ) : props.image.file.contentType === "video/mp4" ? (
            <video
              autoPlay
              controls={false}
              loop
              muted
              playsInline
              preload="auto"
              className={style.video}
            >
              <source
                src={props.image.publicUrl}
                type={props.image.file.contentType}
              />
            </video>
          ) : (
            <></>
          )}
          {props.caption != null ? (
            <figcaption>
              <p className={style.story}>{renderRichText(props.caption)}</p>
            </figcaption>
          ) : (
            <></>
          )}
        </figure>
      ))}
    </div>
  )
}
export default ProjectGrid
